
import React from 'react'
import { hooks } from '@front/volcanion'
import Component from './component'


const Container = props => {
  const { order_id } = props || {}
  const reason_id = hooks.useFieldValue(['reason'])
  const [reason] = hooks.useModel('reason', [reason_id], { single: true })
  const cancelation_comment_required = reason?.comment_required
  const [reason_options] = hooks.useOrderCancelReason(order_id)

  const mergedProps = {
    cancelation_comment_required,
    reason_options
  }
  return <Component {...mergedProps} />
}

export default Container