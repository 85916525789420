import React from 'react'
import { I18n } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import { Box, Typography, Grid, Icon } from '@mui/material'
import { TextComponent, TextField } from '@front/squirtle'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'

const Row1 = ({ price, estimatedDelay, estimation_type = 'none', isManualPriceRequired, schedule_type, empty_fee, margin, isLiveETA }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4} hidden={estimation_type !== 'special'}>
      <TextField
        label={I18n.t(`price.${estimation_type}`)}
        name='manual_price'
        InputProps={{
          endAdornment: <Typography sx={{ ml: 1, mb: 1 }}> {I18n.t('fiat.eur.sign')} </Typography>,
          inputProps: { style: { textAlign: 'right' } }
        }}
        nextField='driver_comment'
        alwaysInclude={isManualPriceRequired}
        required={isManualPriceRequired}
      />
    </Grid>
    <Grid item xs={4} hidden={estimation_type === 'special'}>
      <TextField
        label={I18n.t(`price.${estimation_type}`)}
        value={price || '-'}
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('quotation.approach')}
        value={`${empty_fee} ${I18n.t('fiat.eur.sign')}`}
        disabled
      />
    </Grid>
    <Grid item xs={3} hidden={schedule_type === 'planned'}>
      <TextComponent
        label={I18n.t('transport.estimate_delay')}
        value={estimatedDelay || '-'}
        disabled
        InputProps={{
          startAdornment: !!isLiveETA ? <Icon>{'bolt'}</Icon> : <></>
        }}
      />
    </Grid>
    <Grid item xs={2} hidden={!margin}>
      <TextComponent
        label={I18n.t('traffic_alert.label', { count: 1 })}
        value={margin}
        disabled
      />
    </Grid>
  </Grid>

const Row2 = ({ pickup, distance, duration }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4}>
      <TextComponent
        label={I18n.t('transport.pickUp.user')}
        value={FormatUtils.formatDateBackToFront(pickup, 'DD/MM/YYYY [-] HH[h]mm')}
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('distance.label', { count: 1 })}
        value={distance || 0}
        disabled
      />
    </Grid>
    <Grid item xs={3}>
      <TextComponent
        label={I18n.t('transport.estimate_duration')}
        value={duration || '-'}
        disabled
      />
    </Grid>
  </Grid>

const EstimationSection = props =>
  <Box sx={{ p: 1, borderRadius: 1, boxShadow: 6 }}>
    <Typography variant='h5' >{I18n.t('quotation.estimation')}</Typography>
    <Box sx={{ p: 1 }}>
      <Grid container rowSpacing={1.5}>
        <Row1 {...props} />
        <Row2 {...props} />
      </Grid>
    </Box>
  </Box>


export default React.memo(EstimationSection)
