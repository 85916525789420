import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, IconButton, Typography, StepLabel, StepContent, Icon } from '@mui/material'

import B2C from './B2C'
import B2B from './B2B'

const Header = ({ disabled, isB2B, B2B_ENABLED, onTypeChange }) =>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Typography variant='h5' sx={{ mr: 2 }}>{I18n.t('user.label', { count: 1 })}</Typography>
    <IconButton onClick={() => onTypeChange(false)} disabled={disabled || !B2B_ENABLED} sx={{ p: 0, mx: 1 }}>
      <Icon sx={{ color: !isB2B ? 'primary.main' : 'icon.main' }}>{!!isB2B ? 'person_outline' : 'person'}</Icon>
    </IconButton>
    <IconButton onClick={() => onTypeChange(true)} disabled={disabled || !B2B_ENABLED} sx={{ p: 0, mx: 1 }}>
      <Icon sx={{ color: !!isB2B ? 'primary.main' : 'icon.main' }}>{!!isB2B ? 'business_center' : 'business_center_outline'}</Icon>
    </IconButton>
  </Box>

const ClientSection = props => <>
  <StepLabel><Header {...props} /></StepLabel >
  <StepContent>
    {!!props?.isB2B ? <B2B  {...props} /> : <B2C  {...props} />}
  </StepContent>
</>

export default React.memo(ClientSection)
