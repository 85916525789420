import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'
import HookUtils from '@front/volcanion/utils/hooks'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()

  const [isB2B, setIsB2B] = hooks.useFormState('isB2B')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [isOrderDuplicate] = hooks.useFormState('isOrderDuplicate')
  const [user_id, setUserState] = hooks.useFormState('user_id')

  const user_populate = ['user_companies.companyuser_services.companyservice.contract', 'user_companies.companyuser_services.companyuser', 'auth', 'info', 'customerinfo']

  const PACKAGE_ENABLED = hooks.useRelayFeature('PACKAGE_ENABLED')
  const TAXI_ENABLED = hooks.useRelayFeature('TAXI_ENABLED')
  const CODE_INTERNATIONAL = hooks.useRelayConstant('CODE_INTERNATIONAL')
  const B2B_ENABLED = hooks.useRelayFeature('B2B_ENABLED')

  const [
    application_id,
    commercial_formula,
    load_type,
    companyservice_id,
    payment_type,
    loads,
    userFields
  ] = hooks.useFieldValues(['user.application', 'user.commercial_formula', 'load_type', 'user.companyservice', 'payment_type', 'loads', 'user'])

  const [user] = hooks.useModel('user', [user_id], { populate: user_populate, single: true })
  const [users, searchUser, , { clear: clearUsers }] = hooks.useModelSearch('user', 'get', {
    populate: user_populate,
    search_id: 'customer_search',
    watchers: user_populate
  })

  const [applications] = hooks.useModelSearch('application', 'get', { initial_filter: {} })
  const ridemode_watchers = [load_type, commercial_formula, application_id, user_id, companyservice_id]

  const normalizePhone = useCallback(Callbacks.normalizePhoneHandler(CODE_INTERNATIONAL), [CODE_INTERNATIONAL])
  const onTypeChange = useCallback(Callbacks.onTypeChangeHandler(setIsB2B, setFormValues, loads, userFields), [setIsB2B, setFormValues, loads, userFields])
  const onLoadTypeChange = useCallback(Callbacks.onLoadTypeChangeHandler(user, setFormValues, payment_type), [user, setFormValues, payment_type])
  const onApplicationChange = useCallback(Callbacks.onApplicationChangeHandler(setFormValues, applications, users, user_id), [setFormValues, applications, users, user_id])
  const onFieldClear = useCallback(Callbacks.onFieldClearHandler(clearUsers, setUserState), [clearUsers, setUserState])
  const searchGSM = useCallback(Callbacks.searchGSMHandler(searchUser, application_id), [searchUser, application_id])

  const application_ids = useMemo(() => _.map(applications, 'application_id'), [HookUtils.getRecordsWatcher(applications)])

  const disabled = !!isManagingRecord || !!isOrderDuplicate

  const mergedProps = {
    disabled,
    B2B_ENABLED,
    PACKAGE_ENABLED,
    TAXI_ENABLED,
    application_ids,
    isB2B,
    isManagingRecord,
    ridemode_watchers,
    searchUser,
    normalizePhone,
    onTypeChange,
    onLoadTypeChange,
    onApplicationChange,
    onFieldClear,
    searchGSM,
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
